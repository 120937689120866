<template>
  <b-container fluid>
    <b-modal id="addGiftcard" :title="$t('2023.add_costs')" hide-footer static centered no-close-on-backdrop>
      <div class="form-group chat-search-data m-0 row">
        {{ $t('2023.costs_name') }} <br>
        <input type="text" class="form-control round mb-4" :placeholder="$t('2023.costs_name')" v-model="giftcardName" >
        <div class="row m-0 p-0">
          <div class="col-sm-4 m-0 p-0">
            {{ $t('2023.costs_value') }} <br>
            <input type="number" class="form-control round mb-4" :placeholder="$t('2023.costs_value')" v-model="giftValue" >
          </div>
          <div class="col-sm-4 m-0 p-0">
            {{ $t('2023.costs_value_type') }} <br>
            <b-form-select v-model="giftValueType" style="height: 45px; border-radius: 9px">
              <b-form-select-option value="-" selected>{{ $t('2023.spendings') }}</b-form-select-option>
              <b-form-select-option value="+" selected>{{ $t('2023.income') }}</b-form-select-option>
            </b-form-select>
          </div>
          <div class="col-sm-4 m-0 p-0">
            {{ $t('2023.date') }}<br> <b-form-input v-model="giftcardFrom" type="date" :value="theDate" ></b-form-input>
          </div>
        </div>
        <input type="hidden" v-model="giftcardToken" />
        <div class="col-sm-12 m-0 p-0 text-right">
          <button type="submit" class="btn btn-primary" v-on:click="saveCosts">{{ $t('2023.costs_save') }}</button>
        </div>
      </div>
    </b-modal>
    <b-row>
      <b-col sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('2023.costs') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="addNewCosts">{{ $t('2023.add_costs') }}</b-button>
          </template>
          <template v-slot:body>
            <div style="width: 100%">
              <v-text-field
                v-model="search"
                append-icon="ri-search-line"
                :label="$t('2023.costs_search')"
                single-line
                hide-details
              ></v-text-field>
            </div>
            <v-data-table
              :headers="headers"
              :items="rowData"
              :search="search"
              :footer-props="{
              prevIcon: 'ri-arrow-left-line',
              nextIcon: 'ri-arrow-right-line'
            }"
            >
              <template v-slot:item.type="{ item }">
                <span :class="item.color">{{ item.type }}</span>
              </template>
              <template v-slot:item.name="{ item }">
                {{ item.name }} <br>
                <span style="font-size:11px;">
                  {{ $t('2023.date') }}: {{ item.date }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <button type="button" class="btn btn-default text-success" @click="editCosts(item)">
                  <i class="ri-pencil-line"></i> {{ $t('employees_list.edit') }}
                </button>
                <button type="button" class="btn btn-default text-danger" @click="removeData(item.token)">
                  <i class="ri-delete-bin-line"></i> {{ $t('employees_list.delete') }}
                </button>
              </template>
            </v-data-table>

          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import 'ag-grid-community/dist/styles/ag-grid.min.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import { AllCommunityModules } from '@ag-grid-enterprise/all-modules'
import { core } from '../../config/pluginInit'
import Axios from 'axios'
import { API } from '@/config/api.config'

export default {
  name: 'CostsList',
  mounted () {
    core.index()
    this.getCosts()
  },
  data () {
    return {
      apiToken: localStorage.getItem('api_token') || '',
      companyToken: localStorage.getItem('company_token') || '',
      columnDefs: null,
      rowData: [],
      modules: AllCommunityModules,
      headers: [
        { text: this.$t('2023.costs_value_type'), sortable: true, value: 'type' },
        { text: this.$t('employees_list.name'), align: 'start', sortable: true, value: 'name' },
        { text: this.$t('extra.giftcard_value'), value: 'value' },
        { text: this.$t('employees_list.actions'), value: 'actions' }
      ],
      search: '',
      giftcardName: '',
      giftcardFrom: '',
      giftcardTo: '',
      giftValue: '',
      giftValueType: '-',
      giftcardToken: '',
      theDate: new Date().toJSON().slice(0, 10).replace(/-/g, '.')
    }
  },
  methods: {
    getCosts () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.get(API.API_GET_COMPANY_COSTS)
        .then((response) => {
          if (response.data.status === 'success') {
            if (response.data.costs) {
              const theCosts = response.data.costs
              for (let x = 0; x < theCosts.length; x++) {
                const thetype = theCosts[x].type === '+' ? this.$t('2023.income') : this.$t('2023.spendings')
                const color = theCosts[x].type === '+' ? 'green' : 'red'
                this.rowData.push({
                  token: theCosts[x].token,
                  type: thetype,
                  tip: theCosts[x].type,
                  name: theCosts[x].name,
                  value: theCosts[x].value,
                  date: theCosts[x].thedate,
                  color: color
                })
              }
            }
          }
        })
        .catch((error) => {
          console.error(error.response)
        })
    },
    addNewCosts () {
      this.giftcardName = ''
      this.giftValue = ''
      this.giftValueType = '-'
      this.giftcardFrom = ''
      this.giftcardToken = ''
      this.$bvModal.show('addGiftcard')
    },
    editCosts (costs) {
      this.giftcardName = costs.name
      this.giftValue = costs.value
      this.giftValueType = costs.tip
      this.giftcardFrom = costs.date
      this.giftcardToken = costs.token
      this.$bvModal.show('addGiftcard')
    },
    saveCosts () {
      if (this.giftcardName === '') {
        this.errorCap = this.$t('2023.costs_empty_name')
      } else if (this.giftValue === '') {
        this.errorCap = this.$t('2023.costs_empty_value')
      } else if (this.giftValueType === '') {
        this.errorCap = this.$t('2023.costs_empty_value_type')
      } else {
        const postData = {
          name: this.giftcardName,
          value: this.giftValue,
          type: this.giftValueType,
          date: this.giftcardTo,
          token: this.giftcardToken
        }
        Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
        Axios.post(API.API_ADD_COMPANY_COSTS, postData)
          .then((response) => {
            if (response.data.status === 'success') {
              if (response.data.costs) {
                this.rowData = []
                const theCosts = response.data.costs
                for (let x = 0; x < theCosts.length; x++) {
                  const thetype = theCosts[x].type === '+' ? this.$t('2023.income') : this.$t('2023.spendings')
                  const color = theCosts[x].type === '+' ? 'green' : 'red'
                  this.rowData.push({
                    token: theCosts[x].token,
                    type: thetype,
                    tip: theCosts[x].type,
                    name: theCosts[x].name,
                    value: theCosts[x].value,
                    date: theCosts[x].thedate,
                    color: color
                  })
                }
              }
              this.$bvModal.hide('addGiftcard')
              core.showSnackbar('success', this.$t('2023.costs_added'))
            }
          })
          .catch((error) => {
            console.error(error.response)
          })
      }
    },
    removeData (token) {
      this.$bvModal.msgBoxConfirm(this.$t('2023.costs_delete_message'))
        .then(value => {
          this.boxOne = value
          if (value === true) {
            const formData = {
              token: token
            }
            Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
            Axios.post(API.API_DELETE_COMPANY_COSTS, formData)
              .then((response) => {
                this.$bvModal.hide('addGiftcard')
                if (response.data.status === 'success') {
                  if (response.data.costs) {
                    this.rowData = []
                    const theCosts = response.data.costs
                    for (let x = 0; x < theCosts.length; x++) {
                      const thetype = theCosts[x].type === '+' ? this.$t('2023.income') : this.$t('2023.spendings')
                      const color = theCosts[x].type === '+' ? 'green' : 'red'
                      this.rowData.push({
                        token: theCosts[x].token,
                        type: thetype,
                        tip: theCosts[x].type,
                        name: theCosts[x].name,
                        value: theCosts[x].value,
                        date: theCosts[x].thedate,
                        color: color
                      })
                    }
                  }
                  core.showSnackbar('success', this.$t('2023.costs_delete_success'))
                }
              })
              .catch((error) => {
                console.error(error.response)
                if (error.response.status === 401) {
                  this.$router.push({ name: 'auth1.login' })
                }
              })
          }
        })
    }
  }
}
</script>

<style>
.green{
  color: green;
  font-weight: bold;
}
.red{
  color: red;
  font-weight: bold;
}
</style>
